@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@300;400;500;600;700;800&display=swap');

body {

  margin: 0;
  font-family: 'Poppins', sans-serif;

}

.dark-mode {

  background: linear-gradient(90deg, #000000 0%, #434343 100%);
  transition: all 0.5s;

}

.light-mode {

  background: linear-gradient(90deg, #f5f7fa 0%, #c3cfe2 100%);
  transition: all 0.5s;

}

.curved-div svg {

  display: block;

}

.curved-div.upper {

  width: 100%;

}

#menu {

  position: fixed;
  right: 1rem;
  top: 4.5rem;
  background-color: #111;
  border: 2px solid #fff;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  z-index: 1000;

}